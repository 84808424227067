<template>
 <span v-if="user">
  <editable-page v-if="profile"
    :title="profile && profile.getHandle() ? profile.getHandle() : name"
    :imageUrl="profile.getProfile() && profile.getProfile().getImageUrl()"
    :apiStatusList="[getProfileStatus]"
    :edit="false"
    loginRequired
  >
    <template v-slot:subtitle v-if="hasSubtitle">
      <v-row class="pl-5">
        <v-col>
          <stats-col title="Joined" :cols="6" :md="3" :lg="2" class="font-weight-medium">
            {{ profile && profile.getJoinedOn() && formatDate(profile.getJoinedOn()) }}
          </stats-col>
        </v-col>
        <v-col>
          <stats-col title="Problems Solved" :cols="6" :md="3" :lg="2" class="font-weight-medium">
            {{ profile && profile.getProfile() && profile.getProfile().getProblemsSolved() }}
          </stats-col>
        </v-col>
        <v-col>
          <stats-col title="Name" :cols="6" :md="3" :lg="2" class="font-weight-medium">
            {{ profile && profile.getName() }}
          </stats-col>
        </v-col>
        <v-col>
          <stats-col title="">
            <v-btn class="mb-1" color="accent" @click="profileEditMode()">Edit</v-btn>
          </stats-col>
        </v-col>
      </v-row>
			<!-- <v-row>
				<v-col>
					<ShareNetwork
						network="facebook"
						:url="userProfileLink"
						title="Checkout my CodeDrills Profile"
						description=""
						quote="Learn by doing"
						hashtags="codedrills"
					>
					Share on Facebook
					</ShareNetwork>
				</v-col>
			</v-row> -->
      <v-row>
        <v-col>
          <v-alert v-if="!profile.getHandle() || profile.getHandle() == ''" dense outlined type="error"> You do not have an handle set. <!-- Read <a href="https://discuss.codedrills.io/t/reserve-your-codeforces-handle-on-codedrills">this</a> on details of how to reserve your handle on CodeForces. --> </v-alert>
        </v-col>
      </v-row>
    </template>

    <v-tabs color="accent" v-model="activeTab">
			<v-tab v-for="tab of tabs" :key="tab.index">
				{{tab.name}}
			</v-tab>
      <!-- <v-tab>Career</v-tab> -->
      <v-tab-item>
        <v-row>
          <v-col>
            <competitive-profile
              :competitiveProfiles="
                profile &&
                  profile.getProfile() &&
                  profile.getProfile().getCompetitiveProfilesList()
              "
              @addCPProfile="saveCPProfile"
              @removeProfile="removeCPSite"
              :edit="false"
            >
            </competitive-profile>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card flat outlined>
              <v-card-title>Contests</v-card-title>
              <v-data-table
                class="px-5"
                :headers="headers"
                :items="profile.getProfile() && profile.getProfile().getContestStatsList()"
              >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <router-link  class="text-decoration-none router_link" v-if="item.getContentPreview()" :to="'/contests/' + item.getContentPreview().getUrl()" > {{ item.getContentPreview().getTitle() }} </router-link>
                    <span v-else> Unknown Contest </span>
                  </td>
                  <td>
                    <router-link  class="text-decoration-none router_link" v-if="item.getContentPreview()" :to="'/contests/' + item.getContentPreview().getUrl() + '/scoreboard'" > {{ item.getRank() }} </router-link>
                    <span v-else> {{ item.getRank() }}</span>
                  </td>
                  <td> {{ item.getProblemSolved() }}</td>
                </tr>
              </template>
              </v-data-table>

            </v-card>
          </v-col>
          </v-row>
      </v-tab-item>

     <!-- <!--  <v-tab-item>
        <v-row>
          <v-col>
            <team-dialog :error="teamCreationFailed" @callCreateTeam="handleCreateTeam"></team-dialog>
						<error-message :ex="ex"></error-message>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card flat outlined>
              <v-data-table
                no-data-text="No teams ceated."
                class="px-5"
                :headers="teamHeaders"
                :items="profile.getTeamPreviewsList()"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      <router-link class="text-decoration-none router_link"
                        :to="`/contests/${item.getContestPreview().getUrl()}/teams/${item.getId()}`"
                      >
                        {{ item.getName() }}
                      </router-link>
                    </td>
                    <td>
                      <router-link class="text-decoration-none router_link"
                        v-if="item.getContestPreview()"
                        :to="
                          '/contests/' + item.getContestPreview().getUrl()"
                      >
                        {{ item.getContestPreview().getTitle() }}
                      </router-link>
                    </td>
                    <td>
                      <v-chip outlined color="grey accent" pill class="ma-1" v-for="(member, index) in item.getMemberPreviewsList()" :key="index">
                        <router-link v-if="member.getHandle()" :to="`/profiles/${member.getHandle()}`" class="text-decoration-none router_link">
		            {{member.getHandle()}}
                        </router-link>
			<span v-else>
			    {{member.getName()}}
			</span>
                      </v-chip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item> -->

      <!-- <v-tab-item>
				<v-row>
					<v-col>
						<v-card flat outlined>
							<v-row>
								<v-col>
									<v-card-title>About </v-card-title>
								</v-col>
								<v-col class="text-right">
									<v-dialog v-model="editDone" @keydown.esc="editDone = false">
										<template v-slot:activator="{on, attrs}">
											<v-btn icon v-bind="attrs" v-on="on">
												<img :src="require('./../assets/images/icon/Edit.svg')" />
											</v-btn>
										</template>
										<v-card>
											<v-card-title>About</v-card-title>
											<v-card-text>
												<v-textarea label="About" :value="profile && profile.getProfile() && profile.getProfile().getAbout()" counter maxlength="120" full-width single-line @change="saveAbout" >
												</v-textarea>
											</v-card-text>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn color="accent" text @click="saveProfile">Save</v-btn>
												<v-btn color="accent" text @click="editDone = false">CANCEL</v-btn>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="mt-n10">
									<v-card-text>{{profile && profile.getProfile() && profile.getProfile().getAbout()}}</v-card-text>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<experience-card  :experiences="profile && profile.getProfile() && profile.getProfile().getCareer() && profile.getProfile().getCareer().getExperiencesList()"
						@change="saveExperience"
						@updateTitle="editTitle"
						@updateCompany="editCompany"
						@updateLocation="editLocation"
						@updateProfile="saveProfile"
						>
						</experience-card>
					</v-col>
				</v-row>
			</v-tab-item> -->
    </v-tabs>
  </editable-page>
 </span>
	<span v-else>
		<v-row class="pa-10">
			<v-col>
        Please <router-link to="/community/login"> login </router-link> to view.
			</v-col>
		</v-row>
	</span>
</template>
<script>
import EditablePage from '../components/EditablePage';
import ExperienceCard from '../components/ExperienceCard';
import { mapActions, mapState, mapMutations } from 'vuex';
import CompetitiveProfile from '../components/CompetitiveProfile';
import StatsCol from "../components/StatsCol";
import TeamDialog from "../components/TeamDialog";
import ErrorMessage from "../components/ErrorMessage";

export default {
  props: {
		activeTab: {
			type: Number,
			required: false,
			default: 0,
		},
    handle: {
      type: String,
      required: false,
    },
  },
  data: function() {
    return {
			ex: null,
      editDone: false,
      teamCreationFailed: false,
      search: "",
      headers: [
        {
          text: 'Contest',
          align: 'left',
          width: '20%',
        },
        {
          text: 'Rank',
          align: 'left',
          width: '20%',
        },
        {
          text: 'Problem solved',
          align: 'left'
        },
			],
      teamHeaders: [
        {
          text: 'Team',
          align: 'left',
          width: '20%',
        },
        {
          text: 'Contest',
          align: 'left',
          width: '20%',
        },
        {
          text: 'Team Members',
          align: 'left'
        },
			],
			tabs: [
				{ index: 0, name: 'Competitive' },
				// { index: 1, name: 'Teams' },
      ]
    };
  },
  methods: {
    ...mapActions('user', ['getProfile', 'updateProfile', 'editProfile', 'createTeam']),
    ...mapMutations('user', [
      'setAbout',
      'addExperience',
      'updateTitle',
      'updateCompany',
      'updateLocation',
      'updateCPProfile',
      'removeCPProfile',
    ]),
    handleCreateTeam(details) {
      console.log(" team details:", details);
			this.teamCreationFailed = false;
      this.createTeam(details).then(() => {
        this.$router.push(`/contests/${this.team.getTeamView()
                    .getContentPreview()
                    .getUrl()}/teams/${this.team.getTeamView().getId()}`);
      }).catch(ex => { this.teamCreationFailed = true; this.editDone = false; this.ex = ex});
    },
    profileEditMode(){
      this.$router.push('/profile/edit');
    },
    saveAbout(introduction) {
      this.setAbout(introduction);
    },
    saveExperience(experience) {
      this.addExperience(experience);
      //this.updateProfile().then(__ => this.editDone = false);
    },
    editTitle(title, index) {
      this.updateTitle({ title: title, index: index });
    },
    editCompany(company, index) {
      this.updateCompany({ company: company, index: index });
    },
    editLocation(location, index) {
      this.updateLocation({ location: location, index: index });
    },
    saveProfile() {
      //this.updateProfile().then(__ => this.editDone = false);
    },
    saveCPProfile(cpProfile) {
      this.updateCPProfile(cpProfile);
      this.editProfile().then(__ => (this.editDone = false));
    },
    removeCPSite(i) {
      this.removeCPProfile(i);
      this.editProfile().then(__ => (this.editDone = false));
    },
    formatDate(date) {
      var d = new Date(date);
      var dd = String(d.getDate()).padStart(2, '0');
      var yyyy = d.getFullYear();
      var mmm= d.toLocaleString('default', { month: 'short' });
      d=dd + ' ' + mmm + ' ' + yyyy;
      return d;
    },
  },
  computed: {
    ...mapState('user', ['profile', 'getProfileStatus', 'user', 'team']),
    hasSubtitle() {
      return true;
    },
    name() {
      console.log('profile ', this.profile);
      if (this.profile) return this.profile.getName();
    },
		userHandle() {
			if(this.profile && this.profile.getHandle())
					return this.profile.getHandle();
			else return "To add handle , plese verify your Codeforces handle";
		},
		userProfileLink() {
			console.log("Profile ...", this.profile);
			if(this.profile && this.profile.getHandle()) {
				var url =  "https://codedrills.io/profiles/" + this.profile.getHandle();
				console.log("URL ..", url);
				return url;
			} else {
				return "https://codedrills.io";
			}
		}
  },
  async mounted() {
		console.log("user", this.user);
    this.getProfile();
   this.getProfile().then(res => {
      if(res.getProfileResponse().getHandle() && res.getProfileResponse().getHandle() != "")
          this.$router.push(`/profiles/${res.getProfileResponse().getHandle()}`);
   });
  },
  watch: {
    profile(p) {
      console.log('watch profile', p);
    },
  },
  components: {
    EditablePage,
    ExperienceCard,
    CompetitiveProfile,
    StatsCol,
    TeamDialog,
		ErrorMessage,
  },
};
</script>

<style scoped>
.router_link{
	color:inherit;
}

</style>
